import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Illustration Projects</Text>
                  <Title className="my-4">
                    Iconic American Entertainers Postage
                  </Title>
                </Box>
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img
                src="/projects/stamps/4-stamps.jpg?nf_resize=fit&w=1140"
                alt=""
                className="img-fluid w-100"
              />
            </Box>
          </Container>
        </Section>

        <div className="mt-lg-3">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    For this project I was tasked with picking 4 Iconic American
                    Entertainers and illustrate them for a set of forever
                    postage stamps. The theme I chose to use here is early NY
                    Punk Rock specifically, the CBGB era. A subject I've
                    researched since I first heard the Velvet Underground and
                    Nico. I chose 4 of my personal influences.
                  </Text>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    Patti Smith. Debbie Harry. Lou Reed. Iggy Pop
                  </Text>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  ></Text>
                </Box>
              </Col>
            </Row>
          </Container>
          <div className="mt-lg-3 pt-5">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="8" className="mb-4 mb-lg-0">
                  <Text variant="tag">Tools Used</Text>
                  <Title variant="cardBig" className="mt-3">
                    Procreate <small> (Sketching)</small>, Illustrator
                  </Title>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <div className="mt-lg-3 pt-5">
              <Container>
                <Row>
                  <Title variant="hero" className="mt-3">
                    Process
                  </Title>
                </Row>
              </Container>

              <Box className="pt-4">
                <img
                  src="/projects/stamps/4x/Stamps-Asset1.png?nf_resize=fit&w=1140"
                  alt=""
                  className="img-fluid w-100"
                />
              </Box>
              <Box className="pt-4">
                <img
                  src="/projects/stamps/4x/Stamps-Asset2.png?nf_resize=fit&w=1140"
                  alt=""
                  className="img-fluid w-100"
                />
              </Box>
            </div>
          </Container>
        </div>
        <Section className="mt-lg-5 pb-0">
          <Container>
            <Row>
              <Col xs="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/stamps/DH.PNG?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col xs="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/stamps/PS.PNG?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/stamps/LR.PNG?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/stamps/IP.PNG?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img
                src="/projects/stamps/envelope.jpg?nf_resize=fit&w=1140"
                alt=""
                className="img-fluid w-100"
              />
            </Box>
          </Container>
        </Section>

        <Section bg="dark" className="pt-12">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/info-poster">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Infographic Poster - Stanley Hotel
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
